import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSetting, useSnack } from "store/setting-data";
import { forwardRef } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Snack = () => {
  const snack = useSnack();
  const setting = useSetting();
  console.log("Snack", snack);
  return (
    <Snackbar open={snack.open} autoHideDuration={6000} onClose={setting.closeSnack}>
      <Alert onClose={setting.closeSnack} severity={snack.type} sx={{ width: "100%" }}>
        {snack.message}
      </Alert>
    </Snackbar>
  );
};

export default Snack;
