import { create } from "zustand";

const useStaticStore = create((set) => ({
  apis: null,
  orders_sort_list: null,
  orders_status_filter_list: null,
  payments_operators_list: null,
  payments_sort_list: null,
  payments_status_filter_list: null,
  requests_order_by_list: null,
  requests_order_type_list: null,
  requests_status_filter_list: null,
  roles: null,
  users_filter_list: null,
  actions: {
    setData: (data) => set(() => ({ ...data })),
  },
}));

export const useStaticApis = () => useStaticStore((state) => state.apis);
export const useStaticSortOrder = () => useStaticStore((state) => state.orders_sort_list);
export const useStaticStatusOrder = () => useStaticStore((state) => state.orders_status_filter_list);
export const useStaticOperatorPayment = () => useStaticStore((state) => state.payments_operators_list);
export const useStaticSortPayment = () => useStaticStore((state) => state.payments_sort_list);
export const useStaticStatsPayment = () => useStaticStore((state) => state.payments_status_filter_list);
export const useStaticListRequest = () => useStaticStore((state) => state.requests_order_by_list);
export const useStaticTypeReqest = () => useStaticStore((state) => state.requests_order_type_list);
export const useStaticStatusRequest = () => useStaticStore((state) => state.requests_status_filter_list);
export const useStaticRole = () => useStaticStore((state) => state.roles);
export const useStaticListUser = () => useStaticStore((state) => state.users_filter_list);
export const useGetStaticDataActions = () => useStaticStore((state) => state.actions);
