import { create } from "zustand";

const useUserStore = create((set) => ({
  role: null,
  token: null,
  actions: {
    setUser: (user) => set(() => ({ role: user.role, token: user.token })),
    setUserFromLocalStorage: () =>
      set(() => {
        const userObj = localStorage.getItem("session");

        const user = JSON.parse(userObj);

        if (user) {
          return {
            role: user.role,
            token: user.token,
          };
        } else {
          return {
            role: null,
            token: null,
          };
        }
      }),
  },
}));

export const useUserToken = () => useUserStore((state) => state.token);
export const useUserActions = () => useUserStore((state) => state.actions);
