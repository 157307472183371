import { create } from "zustand";
import { shallow } from "zustand/shallow";
const useSettingStore = create((set) => ({
  snack: {
    open: false,
    message: "",
    type: "success",
  },
  modal: {
    open: false,
    title: "",
    content: "",
  },
  actions: {
    showSnack: ({ message, type }) =>
      set((state) => {
        return {
          ...state,
          snack: {
            open: true,
            message: message,
            type: type,
          },
        };
      }),
    closeSnack: () => set((state) => ({ ...state, snack: { ...state.snack, open: false } })),
    showModal: ({ title, content }) =>
      set((state) => {
        return {
          ...state,
          modal: {
            open: true,
            title: title,
            content: content,
          },
        };
      }),
    closeModal: () => set((state) => ({ ...state, modal: { ...state.modal, open: false } })),
  },
}));

export const useSnack = () => useSettingStore((state) => ({ ...state.snack }), shallow);
export const useModal = () => useSettingStore((state) => ({ ...state.modal }), shallow);
export const useSetting = () => useSettingStore((state) => state.actions);
