import { useStaticDataQuery } from "features/static/hook/useStaticDataQuery";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "routes";
import { useUserActions } from "store/user-data";

function App() {
  const { setUserFromLocalStorage } = useUserActions();
  setUserFromLocalStorage();

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}

export default App;
