import axios from "axios";

const newUser = localStorage.getItem("session") || "{}";
const user = JSON.parse(newUser);
export const apiClient = axios.create({
  baseURL: "https://app.shaku.tech/shaku-api/api/v1",
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${user?.token}`,
  },
});
