import { lazy, Suspense } from "react";

const AuthPage = lazy(() => import("pages/auth"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Request = lazy(() => import("pages/requests"));
const User = lazy(() => import("pages/users"));
const MainPage = lazy(() => import("pages/main"));
const ProfilePage = lazy(() => import("pages/profile"));
export const routes = [
  {
    path: "/login",
    element: (
      <Suspense>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <Suspense>
        <MainPage />
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: "/",
        link: "/",
        title: "Dashboard",
        element: (
          <Suspense>
            <Dashboard />
          </Suspense>
        ),
        showInMenu: true,
      },
      {
        path: "/requests",
        link: "/requests",
        title: "Requests",
        element: (
          <Suspense>
            <Request />
          </Suspense>
        ),
        showInMenu: true,
      },
      {
        path: "/users",
        link: "/users",
        title: "Users",
        element: (
          <Suspense>
            <User />
          </Suspense>
        ),
        showInMenu: true,
      },
      {
        path: "/user/:id/profile",
        link: "/user/:id/profile",
        title: "Profile",
        element: (
          <Suspense>
            <ProfilePage />
          </Suspense>
        ),
        showInMenu: false,
      },
    ],
  },
];
