import { useQuery } from "react-query";
import { useGetStaticDataActions } from "store/static-data";
import { fetchStaticData } from "../api";

export const useStaticDataQuery = () => {
  const { setData } = useGetStaticDataActions();
  const { data, isLoading } = useQuery({
    queryKey: ["static"],
    queryFn: fetchStaticData,
    staleTime: Infinity,
  });
  if (data) {
    console.log("ssdata", data.data);
    setData(data.data);
  }
  return {
    isLoading,
  };
};
