import { Box, Divider, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useModal, useSetting } from "store/setting-data";

const Modal = () => {
  const modal = useModal();
  const { closeModal } = useSetting();
  console.log("close", closeModal);
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 16,
          maxWidth: "700px",
          width: "100%",
          height: "auto",
          padding: 0,
        },
      }}
      open={modal.open}
      onClose={closeModal}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>{modal.title}</span>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>

      <DialogContent>{modal.content}</DialogContent>
    </Dialog>
  );
};

export default Modal;
