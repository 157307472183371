import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { theme as customTheme } from "theme";
import Snack from "components/ui/snack";
import Modal from "components/ui/modal";
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      console.log("eglobalErro", error);
      if (error.response.status === 401) {
        localStorage.removeItem("session");
        window.location.href = "/login";
      }
    },
    onSuccess: (data) => {
      console.log("asdsadsda", data);
      if (data.data.status !== 200) {
        console.log("HHHHH");
      } else {
        return;
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,

      refetchIntervalInBackground: false,
      suspense: false,
    },
    mutations: {
      retry: 2,
    },
  },
});
const theme = createTheme(customTheme);
console.log("test", "test");
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
        <Snack />
        <Modal />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
